export const environment = {
  production: true,
  apiUrl: 'https://demo-client.investigo.uk/api/v1',
  clientId: 'someClientID-demo',
  logoAltText: 'Investigo Logo',
  siteName: 'Investigo - DEMO',
  siteNameSmall: 'DEMO CLIENT',
  showCustomerLogo: '',
  headerLogo: 'logo.png',
  
  mqttData: { 
    hostname: 'insurgo.exinetechnology.com',
    port: 1884,
    rootTopic: '/ltoReader',
    username: 'JWT',
    password: '',
    useTLS: true
  },
  scrapJobIDs:[
  ],
  fixedBarcodeScannerID : 50,
  fixedUSBScannerID : 51,
  fixedScannerLocationID : 0
};
